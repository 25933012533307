import $ from "jquery";

export const footerAccordion = () => {
  const mediaQueryList = window.matchMedia("(max-width: 767px)");

  const accordionBtns = $(".js-footer-accordion-btn");
  const accordionContents = $(".js-footer-accordion-content");

  const listener = (event) => {
    if (event.matches) {
      accordionContents.hide();
      accordionBtns.removeClass("is-active");
    } else {
      accordionContents.show();
      accordionBtns.removeClass("is-active");
    }
  };

  accordionBtns.on("click", function () {
    $(this).next(".js-footer-accordion-content").slideToggle();
    $(this).toggleClass("is-active");
  });

  mediaQueryList.addEventListener("change", listener);
  listener(mediaQueryList);
};
