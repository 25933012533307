export const topHeader = () => {
  const header = document.querySelector("#header");
  const mv = document.querySelector(".js-top-mv");

  if (header && mv) {
    let mvHeight = mv.clientHeight;

    const toggleHeaderColorOnScroll = () => {
      if (window.scrollY > mvHeight) {
        header.classList.remove("is-white");
      } else {
        header.classList.add("is-white");
      }
    };

    toggleHeaderColorOnScroll();

    const handleScroll = () => {
      requestAnimationFrame(toggleHeaderColorOnScroll);
    };

    window.addEventListener("scroll", handleScroll);

    // ウィンドウサイズ変更時にmvHeightを再計算
    window.addEventListener("resize", () => {
      mvHeight = mv.clientHeight;
      toggleHeaderColorOnScroll(); // 再計算後に再適用
    });
  }
};
