export const subPageHeader = () => {
  if (!document.querySelector(".js-sub-page-mv")) {
    return;
  }

  const mediaQueryList = window.matchMedia("(max-width: 1200px)");
  const header = document.querySelector("#header");
  const mv = document.querySelector(".js-sub-page-mv");

  let mvHeight = mv.clientHeight;

  const toggleHeaderColorOnScroll = () => {
    if (window.scrollY > mvHeight) {
      header.classList.remove("is-white");
    } else {
      header.classList.add("is-white");
    }
  };

  const handleScroll = () => {
    requestAnimationFrame(toggleHeaderColorOnScroll);
  };

  const updateHeaderBehavior = () => {
    if (mediaQueryList.matches) {
      // 768px以下の場合
      mvHeight = mv.clientHeight;
      toggleHeaderColorOnScroll();
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateMvHeight);
    } else {
      // 768px以上の場合
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateMvHeight);
      header.classList.remove("is-white");
    }
  };

  const updateMvHeight = () => {
    mvHeight = mv.clientHeight;
    toggleHeaderColorOnScroll();
  };

  mediaQueryList.addEventListener("change", updateHeaderBehavior);
  updateHeaderBehavior(); // 初回呼び出し
};
