export const scrollToTop = () => {
  if (!document.querySelector("#scroll-to-top")) {
    return;
  }

  const scrollToTopBtn = document.querySelector("#scroll-to-top");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  scrollToTopBtn.addEventListener("click", scrollToTop);
};
