export const memberModal = () => {
  if (document.querySelector("#member-modal") === null) return;

  const modal = document.querySelector("#member-modal");
  const modalCount = modal.querySelector(".js-member-modal-count");
  const modalPosition = modal.querySelector(".js-member-modal-position");
  const modalName = modal.querySelector(".js-member-modal-name");
  const modalNameEn = modal.querySelector(".js-member-modal-name-en");
  const modalProfile = modal.querySelector(".js-member-modal-profile-text");
  const modalImg = modal.querySelector(".js-member-modal-img");

  const modalCloseBtns = document.querySelectorAll(".js-member-modal-close");
  const memberBtns = document.querySelectorAll(".js-member-btn");

  memberBtns.forEach((btn) => {
    btn.addEventListener("click", () => {
      document.body.classList.add("is-modal-open");
      modal.classList.add("is-active");

      const count = btn.getAttribute("data-member-count");
      const formattedCount = count.padStart(3, "0");
      modalCount.textContent = formattedCount;

      const position = btn.querySelector(".js-member-position").textContent;
      modalPosition.textContent = position;

      const name = btn.querySelector(".js-member-name").textContent;
      modalName.textContent = name;

      const nameEn = btn.querySelector(".js-member-name-en").textContent;
      modalNameEn.textContent = nameEn;

      const profileText = btn.getAttribute("data-member-profile");
      modalProfile.textContent = profileText;

      const imgSrc = btn.querySelector(".js-member-img").getAttribute("src");
      modalImg.setAttribute("src", imgSrc);
      modalImg.setAttribute("alt", name);
    });
  });

  modalCloseBtns.forEach((btn) => {
    btn.addEventListener("click", () => {
      document.body.classList.remove("is-modal-open");
      modal.classList.remove("is-active");
    });
  });
};
