import Splide from "@splidejs/splide";

export const aboutMvSlider = () => {
  if (document.querySelector(".js-about-mv-slider") === null) return;

  const slider = new Splide(".js-about-mv-slider", {
    type: "fade",
    rewind: true, // fadeとloopを両立させるために必要
    autoplay: true,
    interval: 5000,
    speed: 3000,
    arrows: false,
    drag: false,
    pauseOnHover: false,
    resetProgress: false,
    updateOnMove: true,
    classes: {
      pagination: "mv-slider__pagination",
      page: "mv-slider__page",
    },
  });

  slider.mount();
};
