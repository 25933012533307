import Splide from "@splidejs/splide";

export const tnfdSlider = () => {
  if (document.querySelector(".js-tnfd-slider") === null) return;

  const slider = new Splide(".js-tnfd-slider", {
    arrows: false,
    perPage: 5,
    gap: "3%",
    drag: false,
    classes: {
      pagination: "tnfd-slider__pagination",
      page: "tnfd-slider__page",
    },
    breakpoints: {
      1024: {
        drag: "free",
        perPage: 3,
        // fixedWidth: "18rem",
        wheel: true,
      },
    },
  });

  // const bar = slider.root.querySelector(".tnfd-slider__progress-bar");

  // slider.on("mounted move", function () {
  //   var end = slider.Components.Controller.getEnd() + 1;
  //   var rate = Math.min((slider.index + 1) / end, 1);
  //   bar.style.width = String(100 * rate) + "%";
  // });

  slider.mount();
};
