import Splide from "@splidejs/splide";

export const advisorSlider = () => {
  if (document.querySelector(".js-advisor-slider") === null) return;

  const slider = new Splide(".js-advisor-slider", {
    perPage: 5,
    // fixedWidth: "18rem",
    gap: "4rem",
    classes: {
      pagination: "advisor-slider__pagination",
      page: "advisor-slider__page",
      arrows: "advisor-slider__arrows",
      arrow: "advisor-slider__arrow",
    },
    breakpoints: {
      900: {
        perPage: 4,
        gap: "3rem",
      },
      767: {
        perPage: 3,
        gap: "1rem",
      },
    },
  });

  slider.mount();
};
