import $ from "jquery";

export const hamburgerMenu = () => {
  const btn = document.querySelector("#hamburger-btn");
  const menu = document.querySelector("#hamburger-menu");
  const menuLinks = menu.querySelectorAll("a");

  btn.addEventListener("click", () => {
    document.body.classList.toggle("is-hamburger-menu-open");
  });

  menuLinks.forEach((link) => {
    link.addEventListener("click", () => {
      document.body.classList.remove("is-hamburger-menu-open");
    });
  });

  // accordion
  const accordionBtns = $(".js-hm-accordion-btn");
  const accordionContents = $(".js-hm-accordion-content");

  accordionContents.hide();

  accordionBtns.on("click", function () {
    $(this).next(".js-hm-accordion-content").slideToggle();
    $(this).toggleClass("is-active");
  });
};
