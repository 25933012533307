export const mvVideo = () => {
  // if (!document.querySelector(".js-mv-video")) {
  //   return;
  // }

  // const videoElement = document.querySelector(".js-mv-video");
  // const sourceElement = videoElement.querySelector("source");

  // const pcVideoUrl = videoElement.dataset.pcSrc;
  // const spVideoUrl = videoElement.dataset.spSrc;

  // function setVideoSource() {
  //   const isPC = window.matchMedia("(min-width: 768px)").matches;
  //   const videoSrc = isPC ? pcVideoUrl : spVideoUrl;

  //   // 現在のsrcと異なる場合のみ設定し直す
  //   if (sourceElement.src !== videoSrc) {
  //     sourceElement.src = videoSrc;
  //     videoElement.load(); // srcを変更したらloadで更新
  //   }
  // }

  // // 初期読み込みと画面サイズ変更時にsrcを切り替える
  // window.addEventListener("load", setVideoSource);
  // window.addEventListener("resize", setVideoSource);

  // const soundButton = document.querySelector(".js-mv-sound-btn");

  // soundButton.addEventListener("click", () => {
  //   videoElement.muted = !videoElement.muted;
  //   soundButton.classList.toggle("has-sound");
  // });

  if (!document.querySelector(".js-video-pc")) return;

  const videoPc = document.querySelector(".js-video-pc");
  const videoPcSrc = videoPc.getAttribute("data-src");

  const videoSp = document.querySelector(".js-video-sp");
  const videoSpSrc = videoSp.getAttribute("data-src");

  let pcVideoBool = false;
  let spVideoBool = false;

  if (768 <= window.innerWidth) {
    //画面幅768pxより大きければpc版読み込み
    videoPc.src = videoPcSrc;
    pcVideoBool = true;
  } else {
    //それ以外の場合sp版読み込み
    videoSp.src = videoSpSrc;
    spVideoBool = true;
  }

  window.addEventListener("resize", () => {
    //画面をresize時
    if (768 <= window.innerWidth && !pcVideoBool) {
      //画面幅768pxより大きいかつ、pc版の動画をまだ読み込んでいない場合
      videoPc.src = videoPcSrc;
      pcVideoBool = true;
    }

    if (768 > window.innerWidth && !spVideoBool) {
      //画面幅768px未満かつ、sp版の動画をまだ読み込んでいない場合
      videoSp.src = videoSpSrc;
      spVideoBool = true;
    }
  });

  const soundButton = document.querySelector(".js-mv-sound-btn");

  soundButton.addEventListener("click", () => {
    videoPc.muted = !videoPc.muted;
    videoSp.muted = !videoSp.muted;
    soundButton.classList.toggle("has-sound");
  });
};
