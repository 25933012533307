export const testMeta = () => {
  const titleText = document.querySelector("head title").textContent;

  console.log("タイトル: ", titleText);

  let descText;

  if (document.querySelector('meta[name="description"]') !== null) {
    descText = document.querySelector('meta[name="description"]').getAttribute("content");
    console.log("デスク　: ", descText);
  }

  // const modal = document.createElement("div");
  // modal.classList.add("test-modal");

  // const titleContent = document.createElement("p");
  // titleContent.textContent = titleText;
  // modal.appendChild(titleContent);

  // if (document.querySelector('meta[name="description"]') !== null) {
  //   const descContent = document.createElement("p");
  //   descContent.textContent = descText;
  //   modal.appendChild(descContent);
  // }

  // document.querySelector(".wrap").appendChild(modal);
};
