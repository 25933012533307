import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const ajaxSearch = () => {
  if (!document.querySelector(".js-ajax-search")) return;

  const form = document.querySelector(".js-ajax-search");
  const resultTarget = ".js-ajax-search-result";

  // 同nameのcheckboxが複数ある場合、一つしか選択できないようにする
  // const checkboxes = [...form.querySelectorAll("input[type=checkbox]")];
  // checkboxes.forEach((checkbox) => {
  //   checkbox.addEventListener("change", () => {
  //     checkboxes.forEach((cb) => {
  //       if (cb !== checkbox && cb.name === checkbox.name) {
  //         cb.checked = false;
  //       }
  //     });
  //   });
  // });

  // .js-check-groupの中のcheckboxは、1つしか選択できないようにする
  const checkGroups = [...form.querySelectorAll(".js-check-group")];
  // checkGroups.forEach((checkGroup) => {
  //   const checkboxes = [...checkGroup.querySelectorAll("input[type=checkbox]")];
  //   checkboxes.forEach((checkbox) => {
  //     checkbox.addEventListener("change", () => {
  //       checkboxes.forEach((cb) => {
  //         if (cb !== checkbox) {
  //           cb.checked = false;
  //         }
  //       });
  //     });
  //   });
  // });

  checkGroups.forEach((checkGroup) => {
    const checkboxes = [...checkGroup.querySelectorAll("input[type=checkbox]")];

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        // 現在選択されたチェックボックス以外を全て解除
        checkboxes.forEach((cb) => {
          if (cb !== checkbox) {
            cb.checked = false;
          }
        });

        // チェックボックスが1つもチェックされていない場合は、変更したチェックボックスを再度チェックする
        if (!checkboxes.some((cb) => cb.checked)) {
          checkbox.checked = true;
        }
      });
    });
  });

  form.addEventListener("change", (e) => {
    e.preventDefault();

    gsap.to(resultTarget, {
      alpha: 0,
      duration: 0.3,
    });

    setTimeout(() => {
      // 条件が変更されてtagが表示されている場合は非表示にして、checkedを外す

      //　GETのURLを取得
      const baseUrl = form.getAttribute("action");
      const data = new FormData(form);
      const queryParams = new URLSearchParams(data);
      const url = `${baseUrl}?${queryParams.toString()}`;

      // URLからコンテンツを取得して差し替える
      fetch(url, {
        method: "GET",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
          }

          return response.text();
        })
        .then((text) => {
          // textをHTML要素に変換
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, "text/html");
          const $result = doc.querySelector(resultTarget);

          // textからresultTarget内の要素を取得
          document.querySelector(resultTarget).innerHTML = $result.innerHTML;

          // URLを変更
          history.replaceState(null, null, url);

          // 高さが変わる場合にGSAPのScrollTriggerを更新
          ScrollTrigger.refresh();

          // 透明状態から表示
          setTimeout(() => {
            gsap.to(resultTarget, {
              alpha: 1,
              duration: 0.5,
            });
          }, 500);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, 300);
  });
};
