import $ from "jquery";
import { topHeader } from "./modules/top/header";
import { aboutMvSlider } from "./modules/about-us/aboutMvSlider";
import { scrollToTop } from "./modules/common/scrollToTop";
import { tnfdSlider } from "./modules/service/tnfdSlider";
import { memberModal } from "./modules/member/memberModal";
import { advisorSlider } from "./modules/member/advisorSlider";
import { advisorModal } from "./modules/member/advisorModal";
import { awardViewMoreBtn } from "./modules/award/awardViewMoreBtn";
import { smoothScroll } from "./modules/common/smoothScroll";
import { loopText } from "./modules/top/loopText";
import { inview } from "./modules/common/inview";
import { hamburgerMenu } from "./modules/common/hamburgerMenu";
import { mvVideo } from "./modules/top/mvVideo";
import { footerAccordion } from "./modules/common/footerAccordion";
import { subPageHeader } from "./modules/common/subPageHeader";
import { tnfdScrollBar } from "./modules/service/tnfdScrollBar";
import { ajaxSearch } from "./modules/news/ajaxSearch";
import { testMeta } from "./modules/test-meta";

$(function () {
  // common
  scrollToTop();
  smoothScroll();
  inview(".inview");
  inview(".inview-left");
  inview(".inview-custom");
  hamburgerMenu();
  footerAccordion();
  subPageHeader();

  // top
  topHeader();
  loopText.init();
  mvVideo();

  aboutMvSlider();

  // service
  tnfdSlider();
  tnfdScrollBar();

  memberModal();
  advisorSlider();
  advisorModal();
  awardViewMoreBtn();

  // news
  ajaxSearch();

  testMeta();
});
