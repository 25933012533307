import $ from "jquery";

export const awardViewMoreBtn = () => {
  if ($("#award-view-more-btn").length === 0) return;

  const btn = $("#award-view-more-btn");
  const hiddenItems = $(".js-is-hidden");

  btn.on("click", function () {
    hiddenItems.fadeIn();
    btn.fadeOut();
  });
};
