export const inview = (selector) => {
  const els = document.querySelectorAll(selector);

  const cb = function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // 以下の条件でバグる
        // 1. js-overlap-sectionの中にある
        // 2. ページロード完了時に既に画面内に入っている
        // これを回避するためにsetTimeoutで遅延させる
        setTimeout(() => {
          entry.target.classList.add("inview-is-visible");
          observer.unobserve(entry.target);
        }, 100);
      }
    });
  };

  const options = {
    rootMargin: "-15% 0px",
  };

  const io = new IntersectionObserver(cb, options);

  els.forEach((el) => {
    io.observe(el);
  });
};
